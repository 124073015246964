@media (min-width: 1200px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 1440px !important;
    }
}

.main-body-listing {
    background: #eff3f8;
}
.uyre6k {
    margin: 0;
    text-decoration: underline;
    color: #00384c;
    cursor: default;
}
.right-box {
    margin-bottom: 10px;
}
.icon-lerft {
    margin: 2px 6px 0 0;
}
.right-inner-box {
    width: 100%;
}
.font-clrf {
    font-size: 15px;
    color: #00384c;
    display: flex;
    align-items: end;
    justify-content: space-between;
}
.font-re-s {
    font-size: 14px;
    margin-bottom: 9px;
    color: #454545;
}
.gtuj1 {
    margin-left: 15px;
}
.gtuj {
    margin-left: 10px;
}
.swtsws456 {
    font-size: 12px;
    color: #6D6D6D;
}
.ugbj {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.dertotg {
    border: 1px solid #6D6D6D;
    height: 39px;
    width: 41px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
}

.dertotg:hover {
    background: #00384c;
}
.dertotg:hover img {
    filter: brightness(0) invert(1);
}
.dorht {
    background: #00384c;
}
.dorht img {
    filter: brightness(0) invert(1);
}   
.aopi46 {
    font-size: 12px;
    color: #606060;
    font-weight: 500;
    margin-bottom: 12px;
}
.stgt-line {
    width: 100%;
    height: 2px;
    background: #6D6D6D;
    margin-bottom: 15px;
}
.cirlfjg {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #00384c;
    outline: 1px solid #00384c;
    border: 1px solid white;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
}
.form-check-input{
    margin-top: 0;
    width: 18px;
    height: 18px;
}
.advrt{
    background-color: #ffffff;
    border-radius: 8px;
    border: 1px solid #dddddd;
    font-size: 14px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.20);
    padding: 10px;
    margin-bottom: 10px;
}
.spinner12 {
    font-size: 19px;
    position: relative;
    display: inline-block;
    width: 1em;
    height: 1em;
  }
 
  
  .spinner12 .spinner-blade {
    position: absolute;
    left: 0.4629em;
    bottom: 0;
    width: 0.074em;
    height: 0.2777em;
    border-radius: 0.0555em;
    background-color: transparent;
    -webkit-transform-origin: center -0.2222em;
    -ms-transform-origin: center -0.2222em;
    transform-origin: center -0.2222em;
    animation: spinner-fade9234 1s infinite linear;
  }
  
  .spinner12 .spinner-blade:nth-child(1) {
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  
  .spinner12 .spinner-blade:nth-child(2) {
    -webkit-animation-delay: 0.083s;
    animation-delay: 0.083s;
    -webkit-transform: rotate(30deg);
    -ms-transform: rotate(30deg);
    transform: rotate(30deg);
  }
  
  .spinner12 .spinner-blade:nth-child(3) {
    -webkit-animation-delay: 0.166s;
    animation-delay: 0.166s;
    -webkit-transform: rotate(60deg);
    -ms-transform: rotate(60deg);
    transform: rotate(60deg);
  }
  
  .spinner12 .spinner-blade:nth-child(4) {
    -webkit-animation-delay: 0.249s;
    animation-delay: 0.249s;
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  
  .spinner12 .spinner-blade:nth-child(5) {
    -webkit-animation-delay: 0.332s;
    animation-delay: 0.332s;
    -webkit-transform: rotate(120deg);
    -ms-transform: rotate(120deg);
    transform: rotate(120deg);
  }
  
  .spinner12 .spinner-blade:nth-child(6) {
    -webkit-animation-delay: 0.415s;
    animation-delay: 0.415s;
    -webkit-transform: rotate(150deg);
    -ms-transform: rotate(150deg);
    transform: rotate(150deg);
  }
  
  .spinner12 .spinner-blade:nth-child(7) {
    -webkit-animation-delay: 0.498s;
    animation-delay: 0.498s;
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  
  .spinner12 .spinner-blade:nth-child(8) {
    -webkit-animation-delay: 0.581s;
    animation-delay: 0.581s;
    -webkit-transform: rotate(210deg);
    -ms-transform: rotate(210deg);
    transform: rotate(210deg);
  }
  
  .spinner12 .spinner-blade:nth-child(9) {
    -webkit-animation-delay: 0.664s;
    animation-delay: 0.664s;
    -webkit-transform: rotate(240deg);
    -ms-transform: rotate(240deg);
    transform: rotate(240deg);
  }
  
  .spinner12 .spinner-blade:nth-child(10) {
    -webkit-animation-delay: 0.747s;
    animation-delay: 0.747s;
    -webkit-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg);
  }
  
  .spinner12 .spinner-blade:nth-child(11) {
    -webkit-animation-delay: 0.83s;
    animation-delay: 0.83s;
    -webkit-transform: rotate(300deg);
    -ms-transform: rotate(300deg);
    transform: rotate(300deg);
  }
  
  .spinner12 .spinner-blade:nth-child(12) {
    -webkit-animation-delay: 0.913s;
    animation-delay: 0.913s;
    -webkit-transform: rotate(330deg);
    -ms-transform: rotate(330deg);
    transform: rotate(330deg);
  }
  
  @keyframes spinner-fade9234 {
    0% {
      background-color: #00384c;
    }
  
    100% {
      background-color: transparent;
    }
  }
  .progress, .progress-stacked{
    height: 5px;
  }
  .progress-bar{
    background-color: #00384c;
  }
  .wrh45{
    flex: 1 1;
    border: none;
    background: white;
    color: #00384c;
    padding: 8px;
    text-align: left;
    border-inline-start: .0625rem solid #c1c7cf;
  }
  .rfgrt{
    color: #626971;
}
  .pricve{
    font-size: 18px;
    font-weight: 600;
}
.ur85{
    overflow: hidden;
    border-radius: 10px;
}
.activatefd{
    background: #00384c;
    color: white;
}
.activatefd .rfgrt{
    color: white;
}
.hutd67{
    width: 20% !important;
}
.hutd6{
    width: 55% !important;
}
.hutd674{
    width: 25% !important;
}
.swret5{
    border-radius: .75rem;
    box-shadow: 0 1px 3px 0 #25201f4d;
    color: #161616;
    cursor: pointer;    
}
.ere5e{
    font-size: 18px;
    color: #00384c;
    font-weight: 500;
}
.dt5r4{
    color: #626971;
}
.ws532{
    color: #626971;
    font-size: 11px;
}
.dt34e{
    color: #161616;
    font-size: 11px;
}
.sgyr75{
    border-top-width: 2px;
    width: 88%;
}
.ahr83{
    width: 40px;
    display: flex;
    margin: auto;
}
.dfrtgr6{
    border-right: .0625rem solid #c1c7cf;
}
.erbhu49 {
    border: none;
    background: #00384c;
    padding: 8px 20px;
    border-radius: 8px;
    color: white;
    margin-top: 5px;
}
.edrt54t5 {
    font-size: 18px;
    color: #696969;
    font-weight: 600;
}
.wr35 {
    font-size: 21px;
    font-weight: 400;
    color: #00384c;
}
.farejy {
    text-align: center;
}
.et5345 {
    background: #00384c29;
    width: fit-content;
    padding: 0 2px;
    border-radius: 5px;
    color: #00384c;
}
.dt453w {
    border-bottom: 1px solid #c1c7cf;
    margin: auto;
}
.dt453w1 {
    border-top: 1px solid #c1c7cf;
    margin: auto;
}
.jiw94 {
    color: #00384c;
    font-size: 13px;
    padding: 0 5px;
    border-right: 1px solid #b3b3b3;
}
.erbhu492{
    border: none;
    background: #00384c;
    padding: 3px 12px;
    border-radius: 8px;
    color: white;
}
.de64k{
    font-size: 14px;
    color: #00384c;
}
.dhr95{
    padding: 0 10px;
    /* display: none; */
    border-top: 1px solid #c1c7cf;
}
.acbri{
    transform: scaleY(-1);
}
.rela{
    position: relative;
}
.kirjr4 {
    padding: 5px;
    position: absolute;
    width: fit-content;
    top: 24px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    display: none;
    z-index: 999999;
    background: white;
    left: 50%;
    transform: translate(-50%);
}
.kirjr4:before {
    content: "";
    position: absolute;
    top: -1px;
    left: 49%;
    transform: translate(-50%);
    width: 10px;
    height: 10px;
    background: #ffffff;
    rotate: 45deg;
    z-index: 9;
    border-top: 1px solid #dddddd;
    border-left: 1px solid #dedede;
}
.r6w3:hover .kirjr4 {
    display: block !important;
}
.r6w3{
    width: fit-content;
}
.dr52s{
    font-size: 11px;
}
.mob-filter {
    display: none;
}
.drag12 {
    background: rgb(229 229 229 / 22%);
    border-radius: 19px 10px 0 10px;
    padding: 3px 9px;
    margin: 15px 0;
    width: 100%;
    height: 56px;
    border: 1px solid #00384c;
}
.drag12 img {
    width: 117px;
}
.drag12:hover{
    background: #00384c1c;
}
.button12 {
    width: 105px !important;
}
.text12 {
    font-size: 15px !important;
}
.pagination button {
    border: none;
    background: #00384c;
    color: white;
    margin: 2px;
    width: 30px;
    height: 30px;
    padding: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
}
.pagination {
    justify-content: center;
    margin-top: 20px;
}
@media only screen and (min-width:321px) and (max-width:1024px)  {
    .det4r566{
        display: none;
    }
    .hutd674,
    .hutd6{
        width: 100% !important;
    }
    .dfrtgr6 {
        border-right: none;
        border-bottom: .0625rem solid #c1c7cf;
    }
    .jiw94 {
        font-size: 10px;
        text-align: center;
    }
    .de64k {
        font-size: 13px;
    }
    .mob-filter {
        display: flex;
        background: #00384c1c;
        padding: 4px;
        justify-content: center;
        align-items: center;
        border-radius: 2px;
        width: 96%;
        margin: 10px auto;
    }
    .sdeyady {
        width: 100%;
        height: 56px;
        background: #00384c;
        border-color: #00384c;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        font-size: 18px;
    }
}
@media only screen and (max-width: 600px) {

    .det4r566{
        display: none;
    }
    .hutd674,
    .hutd6{
        width: 100% !important;
    }
    .dfrtgr6 {
        border-right: none;
        border-bottom: .0625rem solid #c1c7cf;
    }
    .jiw94 {
        font-size: 10px;
        text-align: center;
    }
    .de64k {
        font-size: 13px;
    }
    .mob-filter {
        display: flex;
        background: #00384c1c;
        padding: 4px;
        justify-content: center;
        align-items: center;
        border-radius: 2px;
        width: 96%;
        margin: 10px auto;
    }
    .sdeyady {
        width: 100%;
        height: 56px;
        background: #00384c;
        border-color: #00384c;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        font-size: 18px;
    }
}
.ut48r {
    font-weight: 400;
    font-size: 12px;
    text-decoration: underline;
}