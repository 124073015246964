@media only screen and (max-width: 767px) {
   .hide-mobile{
    display: none !important;
   }
   .adsdr{
    width: 100%;
    text-align: center;
   }
   .fgyf133{
    border-left: none !important;
    border-radius: 0 10px 10px 0;
  }
  .etwset{
    font-size: 14px;
  }
  .fgyf33{
    border-right: none !important;
    border-radius: 10px 0 0 10px;
  }
   .skmujk{
    margin-bottom: 17px;
}
.dgsdgvsd{
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.fdyhdf{
  display: flex;
 }
.fggd{
  padding: 6px 29px;
  font-weight: 600;
}
.contenu12{
  background: linear-gradient(to right, #ffffffeb, #ffffffeb);
  color: #00384c;
}
.card3rr3{
  background: #ffffff;
}
.wrfdsdf {
  background-color: #ffffff;
  color: #00384c !important;
}
.btis{
  width: 96px !important;
}
.card3rr3 .drag12 img {
  width: 115px;
}
   .show-mobile{
    display: block !important;
   }
   .main-bg {
    height: inherit !important;
    padding: 20px;
    z-index: 9999;
    background: rgb(247 247 247);
}
   .tab-brder {
    width: fit-content;
  }
  .card::before {
    display: none;
  }
  .circular {
    width: 20px;
    margin: 0px;
}
.plan-fly2 {
  width: 22px;
}
.plan-fly,
.plan-fly1 {
  display: none;
}
.navbar-toggler {
  border: none!important;
}
.arrow-right {
  rotate: 90deg;
}
.card3rr3 {
  padding: 25px;
  width: 96% ;
}
section {
  padding: 40px 0;
}
.loader {
  left: 50%;
  transform: translate(-50%);
  border: 1px solid white;
  width: 91%;
}
.fgdfhg{
  filter: brightness(0) invert(1);
  border: 1px solid white;
    border-radius: 9px;
    padding: 2px 5px;
}
.card3rr3 .drag12 {
  width: 99%;
}
.draw {
  width: 135px;
  top: 0;
}
.roung {
  height: 280px;
  border-radius: 0 50px 0 50px;
}
.posir {
  width: 159px;
}
.sfrwsr{
  font-size: 13px;
}
.pldn {
  width: 100px;
}
.trav_form {
  width: 327px;
}
.pr-pol {
  padding: 20px;
}
.bred-ban::before {
  bottom: -2px;
  width: 100%;
  height: 100%;
}
.button12{
  margin-left: 0;
}
.bred-ban::after{
  display: none;
}
.partition{
  display: none;
}
/* .dripd{
  margin-bottom: -43px;
} */
.ding{
  display: block !important;
}
.addr {
  justify-content: center;
  font-size: 12px;
  position: inherit;
  width: 100%;
}
.addr h6{
  font-size: 15px;
}
.icon-xchng1{
  top: 64px !important;
    left: 50% !important;
    width: 32px !important;
    height: 32px !important;
    rotate: 90deg !important;
}

.thyt{
  margin-bottom: 10px !important;
}
.dtgtth1,
.dtgtth{
  position: initial;
}
.dtgtth1 img{
  transform: initial;
}
.inner-card {
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  border-radius: 8px;
  padding: 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}
.ry6hvg{
  padding: 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}
.ani-sec::before{
  left: -8%;
  width: 385px;
  height: 388px;
}
.ani-sc-im {
  width: 354px;
  height: 358px;
}
.saedrt{
  flex-direction: row;
}
.firy {
  padding-top: 20px;
}
.ihtf{
  position: inherit;
  bottom: inherit;
  right: inherit;
}
.orfw,
.ihtf{
  width: 100%;
}
.backt {
  position: inherit;
  left: inherit;
  top: inherit;
  transform: inherit;
  width: inherit;
  height: inherit;
}
/* .dtdtd{
  display: none !important;
} */
.detges{
  font-size: 18px;
}
.dgdg{
  justify-content: space-between !important;
}
.main-banner-im{
  background: #00384c !important;
}
.asdfgsd {
  bottom: -162px !important;
}
  }

  @media only screen and (min-width: 1022px) {

    .container{
      max-width: 1230px !important;
    }
    
}


@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .main-banner-im{
    background: #00384c !important;
  }
  /* .dtdtd{
    display: none !important;
  } */
  .thyt{
    margin: 0 !important; 
  }
  .slick-prev {
    right: 70px;
    left: inherit;
    top: -66px;
}
.slick-next {
  right: 26px;
  top: -66px;
}
.draw {
  width: 142px;
}
.arrow-right {
  rotate: 90deg;
}
.card3rr3 .drag12 {
  width: 93%;
  margin-bottom: 15px !important;
}
.futjg{
  padding-left: 60px;
}
.backt {
    position: absolute;
    left: 17px;
    top: 50%;
    transform: translate(0, -50%);
    width: 35px;
    height: 35px;
}
.uft{
  display: none !important;
}
.navbar-toggler {
  border: none!important;
}
.main-bg{
  z-index: 999!important;
}
.show-mobile{
  display: block !important;
  width: 33px !important;
 }
 .partition{
  display: none;
}
.pr-pol {
  padding: 25px;
}
.bred-ban::before {
  bottom: -73px;
  width: 53%;
}
.bred-ban::after {
  bottom: -24px;
  width: 49%;
  height: 100%;
}
.ding{
  display: block !important;
}
.ry6hvg {
  margin-bottom: 35px;
}
.dtgtth,
.dtgtth1{
  position: inherit;
  top: inherit;
  right: inherit;
  left: inherit;
  transform: inherit;
}
.dtgtth1 img{
  transform: inherit;
}
.firy {
  padding-top: 20px;
}
.icon-xchng1 {
  left: 50% !important;
  transform: translate(-38%, 0) !important;
  top: 78px !important;
  rotate: 90deg !important;
  width: 33px !important;
  height: 33px !important;
}
.skmujk {
  margin-bottom: 15px;
}
.dgdg{
  justify-content: space-between !important;
}
.contenu12{
  background: linear-gradient(to right, #ffffffeb, #ffffffeb);
  color: #00384c;
}
.card3rr3{
  background: #ffffff;
}
.wrfdsdf {
  background-color: #ffffff;
  color: #00384c !important;
}
.fgdfhg{
  filter: brightness(0) invert(1);
  border: 1px solid white;
  border-radius: 9px;
  padding: 2px 5px;
}
.asdfgsd {
  width: 97%;
}
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {

  /* CSS */

  .card3rr3 .drag12 {
    width: 87%;
}
.rt7tur {
  left: 177px !important;
}
.addr {
  bottom: 48px;
}
.fredt {
  font-size: 12px;
}
}