/* .App {
    font-family: sans-serif;
    text-align: center;
  } */
  .two-month-range-calendar-container {
    /* text-align: center; */
    position: absolute;
    border-radius: 2px;
    padding: 0px;
  }
  .rdrDefinedRangesWrapper{
    display: none;
  }
  .hoverEffect:hover {
    background-color: rgb(238, 83, 56);
  }
  
  .button-container {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .button-container button {
    margin: 10px 0;
    padding: 8px 16px;
    background-color: #007bff;
    color: #ffffff;
    border: none;
    cursor: pointer;
  }
  
  .button-container button:hover {
    background-color: #0056b3;
  }
  
  .rdrMonth{
    width: 18.667em;
  }

  @media (max-width: 768px) {
    .two-month-range-calendar-container {
      padding: 10px;
    }
  
    .button-container {
      flex-direction: row;
      justify-content: center;
    }
  }
  