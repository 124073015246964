@keyframes callring{
    10% {
        -webkit-transform: rotate(10deg);
        -moz-transform: rotate(10deg);
        -o-transform: rotate(10deg);
    }
    
    20% {
        -webkit-transform: rotate(-10deg);
        -moz-transform: rotate(-10deg);
        -o-transform: rotate(-10deg);
    }
    30% {
        -webkit-transform: rotate(10deg);
        -moz-transform: rotate(10deg);
        -o-transform: rotate(10deg);
    }
    31% {
        -webkit-transform: rotate(0);
        -moz-transform: rotate(0);
        -o-transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(0);
        -moz-transform: rotate(0);
        -o-transform: rotate(0);
    }
    60% {
        -webkit-transform: rotate(0);
        -moz-transform: rotate(0);
        -o-transform: rotate(0);
    }
    79% {
        -webkit-transform: rotate(0);
        -moz-transform: rotate(0);
        -o-transform: rotate(0);
    }
    80% {
        -webkit-transform: rotate(-10deg);
        -moz-transform: rotate(-10deg);
        -o-transform: rotate(-10deg);
    }
    90% {
        -webkit-transform: rotate(10deg);
        -moz-transform: rotate(10deg);
        -o-transform: rotate(10deg);
    }
    100% {
        -webkit-transform: rotate(-10deg);
        -moz-transform: rotate(-10deg);
        -o-transform: rotate(-10deg);
    }
}

@keyframes rotate {
    from {
      -webkit-transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(359deg);
    }
  }
@keyframes cloud{
    0% {
        right: 0px;
    }
    100% {
        right: 100%;
    }
}
@keyframes cloud1{
    0% {
        left:  0px;
    }
    100% {
        left:  100%;
    }
}
@keyframes sp-anime {
    100% { 
      transform: rotate(360deg); 
    }
  }
  @keyframes loader{
    50%{
      width: 64px;
    }
  }
  @keyframes wave12 {
    0% {
      transform: translate(-50%, -75%) rotate(0deg);
    }
  
    100% {
      transform: translate(-50%, -75%) rotate(300deg);
    }
  }
  @keyframes slide1 {
    0%,
    100% {
      transform: translate(0, 0);
    }
  
    50% {
      transform: translate(10px, 0);
    }
  }
  @keyframes rotBGimg3rr3 {
    from {
      transform: rotate(0deg);
    }
  
    to {
      transform: rotate(360deg);
    }
  }

  @keyframes glowing-pulse213 {
    0% {
      box-shadow: 0 0 0 0 rgb(0 56 79);
    }
  
    70% {
      box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
    }
  
    100% {
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
  }
  @keyframes leftToRight13344{
    0%, 100% {
      left: 0px;
  }
  
  50% {
      left: 56px;
  }
  }
  
  @keyframes slide124{
    0%, 100% {
      transform: translate(0, 0);
  }
  50% {
      transform: translate(10px, 0);
  }
  }
  @keyframes spin2323{
    100% {
      transform: rotate(360deg);
  }
  }
  @keyframes fadeinout456
{
  0%{
    opacity:0;
  }
  50%
  {
    opacity:1;
  }
  100%
  {
    opacity:0;
  }
}
@keyframes rippleff {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.1), 0 0 0 20px rgba(255, 255, 255, 0.1), 0 0 0 40px rgba(255, 255, 255, 0.1), 0 0 0 60px rgba(255, 255, 255, 0.1);
  }

  100% {
    box-shadow: 0 0 0 20px rgba(255, 255, 255, 0.1), 0 0 0 40px rgba(255, 255, 255, 0.1), 0 0 0 60px rgba(255, 255, 255, 0.1), 0 0 0 80px rgba(255, 255, 255, 0);
  }
}
@keyframes animate99o {
  to {
    transform: rotate(1turn);
  }
}
@keyframes sliderShape {
  0%, 100% {
    border-radius: 42% 58% 70% 30% / 45% 45% 55% 55%;
    transform: translate3d(0, 0, 0) rotateZ(0.01deg);
}
34% {
    border-radius: 70% 30% 46% 54% / 30% 29% 71% 70%;
    transform: translate3d(0, 5px, 0) rotateZ(0.01deg);
}
50% {
    transform: translate3d(0, 0, 0) rotateZ(0.01deg);
}
67% {
    border-radius: 100% 60% 60% 100% / 100% 100% 60% 60%;
    transform: translate3d(0, -3px, 0) rotateZ(0.01deg);
}
}